import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { X } from 'lucide-react'; // Import the X component from lucide-react
import styles from './UploadPage.module.css'; // Import CSS module

function UploadPage({ onUploadStart }) {
  const [files, setFiles] = useState([]);
  const [userEmail, setUserEmail] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const response = await axios.get('/is_authenticated');
        if (response.data.isAuthenticated) {
          setUserEmail(response.data.email);
        }
      } catch (error) {
        console.error('Error fetching user email:', error);
      }
    };

    fetchUserEmail();
  }, []);

  const getFirstName = (email) => {
    if (!email) return '';
    const namePart = email.split('@')[0];
    const firstName = namePart.split(/[.\s]/)[0];
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  };

  const handleFileChange = (e) => {
    setFiles([...files, ...Array.from(e.target.files)]);
  };

  // Define the handleRemoveFile function to remove a file from the list
  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (files.length === 0) return;

    let uploadedImages = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('files[]', file);

      try {
        const response = await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.images) {
          uploadedImages = [...uploadedImages, ...response.data.images];
        }
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }

    if (uploadedImages.length > 0) {
      navigate('/', { state: { uploadedImages, isProcessing: true } });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src="static/assets/logo.png" alt="Logo" className={styles.logo} />
      </div>

      <div className={styles.headerRight}>
        <span className={styles.userName}>{getFirstName(userEmail)}</span>
        <button className={styles.buttonLogout} onClick={() => {
          axios.get('/logout').then(() => {
            localStorage.removeItem('isAuthenticated');
            window.location.href = '/login';
          });
        }}>Logout</button>
      </div>

      <h1 className={styles.uploadTitle}>Upload Documents</h1>

      <div className={styles.controlPanel}>
        <div className={styles.step}>Step 1</div>
        <input
          type="file"
          id="file-upload"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          multiple
          accept="image/*,application/pdf"
        />
        <label htmlFor="file-upload" className={styles.buttonSelect}>Select Files</label>

        {/* Display selected files */}
        {files.length > 0 && (
          <div className={styles.selectedFiles}>
            <h3>Selected Files:</h3>
            <ul>
              {files.map((file, index) => (
                <li key={index} className={styles.fileItem}>
                  <span className={styles.fileName}>{file.name}</span>
                  <button onClick={() => handleRemoveFile(index)} className={styles.removeFile}>
                    <X size={16} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className={styles.step}>Step 2</div>
        <button className={styles.buttonUpload} onClick={handleUpload}>Upload</button>
      </div>
    </div>
  );
}

export default UploadPage;

