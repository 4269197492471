import React from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';

const PrivateRoute = ({ element: Component, requiredRole, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasRole, setHasRole] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null); // Add customer state

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/is_authenticated');
        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
          setCustomer(response.data.customer); // Set customer state
          if (requiredRole) {
            const userRole = response.data.role;
            setHasRole(userRole === requiredRole);
          } else {
            setHasRole(true);
          }
        } else {
          setIsAuthenticated(false);
          setHasRole(false);
        }
      } catch (error) {
        console.error('Error checking authentication', error);
        // Optionally handle the error, e.g., redirect to an error page
        setIsAuthenticated(false);
        setHasRole(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [requiredRole]);

  if (loading) {
    return (
      <div className="loading-container">
        {/* Replace with a spinner or styled loading component */}
        Loading...
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (isAuthenticated && !hasRole && requiredRole) {
    return <Navigate to="/" />; // Redirect to home if authenticated but lacks the required role
  }

  return <Component {...rest} customer={customer} />; // Pass customer as prop
};

export default PrivateRoute;

