import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './Login';
import Admin from './Admin';
import Archive from './Archive';
import CreateUser from './CreateUser'; // Import the CreateUser component
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Logout from './Logout'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/" element={<PrivateRoute element={App} />} />
        <Route path="/admin" element={<PrivateRoute element={Admin} requiredRole="Admin" />} />
        <Route path="/admin/users/create" element={<PrivateRoute element={CreateUser} requiredRole="Admin" />} />
        <Route path="/archive" element={<PrivateRoute element={Archive} />} />
        <Route path="*" element={<Navigate to="/" />} /> {/* Redirect any unknown paths to login */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals.console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
