import React, { useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

const Logout = () => {
  useEffect(() => {
    const logout = async () => {
      await axios.get('/logout');
      localStorage.removeItem('isAuthenticated');
      window.location.href = '/login';
    };

    logout();
  }, []);

  return <Navigate to="/login" />;
};

export default Logout;
