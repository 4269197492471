import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/login', new URLSearchParams({
        email: email,
        password: password
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      if (response.status === 200) {
        localStorage.setItem('isAuthenticated', true);
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Login failed', error);
      setError('Invalid credentials. Please try again.');
      setTimeout(() => setError(''), 5000);
    }
  };

  return (
    <div className="container">
      <div className="login-form">
        <h1 className="login-title">Login</h1>
        <div className="form-container">
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="input-field">
              <input
                type="text"
                className="input"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-field">
              <input
                type="password"
                className="input"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="button-group">
              <button type="submit" className="button">Sign In</button>
            </div>
            <div className="text-link">
              <a href="#">Forgot password?</a>
            </div>
          </form>
          {error && (
            <div className="error-popup">
              {error}
            </div>
          )}
        </div>
      </div>
      <div className="logo-container">
        <img src="static/assets/logo.png" alt="Logo" className="logo" />
      </div>
    </div>
  );
}

export default Login;
