import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Admin.css';

function Admin() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/admin/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setUsers([]);  // Set an empty array if there's an error
      }
    };
    fetchUsers();
  }, []);

  const handleCreateUser = () => {
    window.location.href = '/admin/users/create';
  };

  return (
    <div className="admin-container">
      <h2>User Management</h2>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Role</th>
            <th>Is Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(users) && users.map((user) => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>{user.is_active ? 'Yes' : 'No'}</td>
              <td>
                <a href={`/admin/users/edit/${user.id}`}>Edit</a>
                <a href={`/admin/users/delete/${user.id}`}>Delete</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleCreateUser}>Create New User</button>
    </div>
  );
}

export default Admin;
