import React, { useState, useRef, forwardRef, useEffect } from 'react';

const EditableField = forwardRef(({ id, label, value, originalValue, onChange, confidenceCheck }, ref) => {
  const [inputValue, setInputValue] = useState(value);
  const [initialValue, setInitialValue] = useState(value); // Store the initial value as original value
  const localRef = useRef(null);
  const inputRef = ref || localRef;

  useEffect(() => {
    setInputValue(value);
    setInitialValue(value); // Set the initial value only when the component mounts
  }, [value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  const isModified = inputValue !== initialValue; // Compare with the initial value
  const isLowConfidence = confidenceCheck === false; // Check if confidenceCheck is false

  return (
    <div className="form-group">
      <label htmlFor={id} className="form-label">{label}</label>
      <input
        ref={inputRef}
        type="text"
        className={`form-control ${isModified ? 'highlight-modified' : ''} ${isLowConfidence ? 'highlight-low-confidence' : ''}`} // Apply highlight class
        id={id}
        name={id}
        value={inputValue}
        onChange={handleChange}
      />
    </div>
  );
});

export default EditableField;
