import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Archive() {
    const [uploads, setUploads] = useState([]);
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        const fetchUploads = async () => {
            try {
                const response = await axios.get('/is_authenticated');
                setCustomer(response.data.customer);

                const uploadsResponse = await axios.get(`/uploads/${response.data.customer}`);
                setUploads(uploadsResponse.data);
            } catch (error) {
                console.error('Error fetching uploads:', error);
            }
        };

        fetchUploads();
    }, []);

    const getImagePath = (upload) => {
        // If the image is part of a multi-page PDF (hence in a subdirectory)
        if (upload.image_path.includes('/')) {
            console.log(`/uploads/${customer}/images/${upload.image_path}`);
            return `/uploads/${customer}/images/${upload.image_path}`;
        }
        // Otherwise, it's a direct image
        console.log(`/uploads/${customer}/images/${upload.image_name}`);
        return `/uploads/${customer}/images/${upload.image_name}`;
    };

    return (
        <div className="archive-page">
            <h2>Archive</h2>
            <div className="image-grid">
                {uploads.map((upload, index) => (
                    <div key={index} className="image-card">
                        <img src={getImagePath(upload)} alt={upload.image_name} />
                        <p>{upload.image_name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Archive;

