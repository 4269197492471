import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import UploadPage from './UploadPage';
import EditableField from './EditableField';
import styles from './App.module.css'; // Using CSS module

function App() {
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const [imageNames, setImageNames] = useState(location.state?.uploadedImages || []);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [processingFiles, setProcessingFiles] = useState(location.state?.isProcessing ? location.state.uploadedImages : []);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [ocrResult, setOcrResult] = useState({});
  const [initialOcrResult, setInitialOcrResult] = useState({});
  const [uploadComplete, setUploadComplete] = useState(false);
  const [scale, setScale] = useState(1);
  const [userEmail, setUserEmail] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [pageImages, setPageImages] = useState([]);
  const [pdfDir, setPdfDir] = useState('');
  const maxScale = 1.6;
  const minScale = 1;
  const inputRefs = useRef({});
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/is_authenticated');
        if (response.data.isAuthenticated) {
          setUserEmail(response.data.email);
          setCustomer(response.data.customer);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (location.state?.uploadedImages) {
      handleUploadStart(location.state.uploadedImages);
    } else if (imageNames.length > 0) {
      setProcessingFiles(imageNames);
      processFiles(imageNames);
    }
  }, [location.state, imageNames]);

  const getField = (data, field) => data ? data[field] : undefined;

  const getFirstName = (email) => {
    if (!email) return '';
    const namePart = email.split('@')[0];
    const firstName = namePart.split(/[.\s]/)[0];
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  };

  const handleUploadStart = (uploadedImages) => {
    setFiles(uploadedImages);
    setImageNames(uploadedImages);
    setProcessingFiles(uploadedImages);
    setUploadComplete(true);
    processFiles(uploadedImages);
  };

  const handleHeaderUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    const newFileNames = newFiles.map(file => file.name);
    setImageNames((prevNames) => [...prevNames, ...newFileNames]);
    setProcessingFiles(newFileNames);
    uploadFiles(newFiles);
  };

  const uploadFiles = async (filesToUpload) => {
    let uploadedFiles = [];
    for (const file of filesToUpload) {
      const formData = new FormData();
      formData.append('files[]', file);

      const response = await axios.post('/upload', formData);
      if (response.data.images) {
        uploadedFiles = [...uploadedFiles, ...response.data.images];
      }
    }
    setProcessingFiles(uploadedFiles);
    processFiles(uploadedFiles);
  };

  const processFiles = async (uploadedFiles) => {
    for (const filename of uploadedFiles) {
      await axios.post('/process', { filenames: [filename] });
      await saveOcrData(filename);
      setProcessingFiles((prev) => prev.filter((file) => file !== filename));
    }
    setUploadComplete(true);
  };

  const fetchOcrResult = async (filename) => {
    try {
      const response = await axios.get(`/load_json/${filename}`);
      setOcrResult(response.data);
    } catch (error) {
      console.error('Error fetching OCR result:', error);
    }
  };

  const saveOcrData = async (filename) => {
    try {
      const response = await axios.post('/save_ocr_data', {
        filename: filename,
        customer: customer,
        ocrData: ocrResult,
      });

      if (response.status === 200) {
        console.log('OCR data saved successfully.');
      } else {
        console.error('Error saving OCR data.');
      }
    } catch (error) {
      console.error('Error saving OCR data:', error);
    }
  };

  const fetchPageImages = async (filename) => {
    try {
      const response = await axios.get(`/get_page_images/${filename}`);
      const { pageImages, pdfDir } = response.data;
      setPageImages(pageImages);
      setPdfDir(pdfDir || '');
    } catch (error) {
      console.error('Error fetching page images:', error);
    }
  };

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setCurrentPageIndex(0);
    fetchOcrResult(imageNames[index]);
    if (isPDF(imageNames[index])) {
      fetchPageImages(imageNames[index]);
    } else {
      setPageImages([imageNames[index]]);
    }
  };

  const handleZoomIn = () => {
    if (scale < maxScale) setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale > minScale) setScale(scale - 0.1);
  };

  const handleMouseMove = (e) => {
    if (containerRef.current && imageRef.current) {
      const { left, top, width, height } = containerRef.current.getBoundingClientRect();
      const x = ((e.clientX - left) / width) * 100;
      const y = ((e.clientY - top) / height) * 100;
      imageRef.current.style.transformOrigin = `${x}% ${y}%`;
      imageRef.current.style.transform = `scale(${scale})`;
    }
  };

  const isPDF = (filename) => filename.toLowerCase().endsWith('.pdf');

  const handleInputChange = (field, subfieldKey, index, value, refId) => {
    const cursorPosition = inputRefs.current[refId]?.selectionStart;
    setOcrResult((prevState) => {
      const updatedData = { ...prevState };

      if (index !== undefined) {
        if (!getField(updatedData, field)) updatedData[field] = [];
        if (!getField(updatedData[field], index)) updatedData[field][index] = {};
        if (!getField(updatedData[field][index], subfieldKey)) updatedData[field][index][subfieldKey] = {};
        updatedData[field][index][subfieldKey].value = value;
      } else if (subfieldKey !== undefined) {
        if (!getField(updatedData, field)) updatedData[field] = {};
        if (!getField(updatedData[field][subfieldKey])) updatedData[field][subfieldKey] = {};
        updatedData[field][subfieldKey].value = value;
      } else {
        if (!getField(updatedData, field)) updatedData[field] = {};
        updatedData[field].value = value;
      }

      setTimeout(() => {
        const input = inputRefs.current[refId];
        if (input) {
          input.focus();
          input.setSelectionRange(cursorPosition, cursorPosition);
        }
      }, 0);

      return updatedData;
    });
  };

  const FormGroup = ({ id, label, value, onChange, refId, originalValue }) => {
    const getValue = (data) => data && data.value !== undefined ? data.value : data?.properties?.value;
    const getConfidenceCheck = (data) => data && data.confidenceCheck !== undefined ? data.confidenceCheck : data?.properties?.confidenceCheck;

    return (
      <EditableField
        id={id}
        label={label}
        value={getValue(value) || ''}
        originalValue={originalValue ? getValue(originalValue) : ''}
        confidenceCheck={getConfidenceCheck(value)}
        onChange={(value) => onChange(value, refId)}
        ref={(el) => (inputRefs.current[refId] = el)}
      />
    );
  };

  const FieldComponent = ({ field, data, originalData }) => {
    const fieldData = getField(data, field.key);
    const originalFieldData = getField(originalData, field.key);

    return (
      <div key={field.key} className={styles.fieldComponent}>
        <h5 className={styles.fieldLabel}>{field.label}</h5>
        {Array.isArray(fieldData) ? (
          fieldData.map((item, index) => (
            <div key={`${field.key}-${index}`} className={styles.subfieldContainer}>
              {field.subfields.map((subfield) => (
                <FormGroup
                  key={`${field.key}-${index}-${subfield.key}`}
                  id={`${field.key}-${index}-${subfield.key}`}
                  label={subfield.label}
                  value={getField(item, subfield.key)}
                  onChange={(value, refId) => handleInputChange(field.key, subfield.key, index, value, refId)}
                  refId={`${field.key}-${index}-${subfield.key}`}
                  originalValue={getField(originalFieldData?.[index], subfield.key)}
                />
              ))}
            </div>
          ))
        ) : (
          field.subfields.map((subfield) => (
            <FormGroup
              key={`${field.key}-${subfield.key}`}
              id={`${field.key}-${subfield.key}`}
              label={subfield.label}
              value={getField(fieldData, subfield.key)}
              onChange={(value, refId) => handleInputChange(field.key, subfield.key, undefined, value, refId)}
              refId={`${field.key}-${subfield.key}`}
              originalValue={getField(originalFieldData, subfield.key)}
            />
          ))
        )}
      </div>
    );
  };

  const InfoCostSection = ({ data, originalData }) => (
    <div className={styles.infoCostSection}>
      <div className={styles.infoSection}>
        <h5>Invoice Info</h5>
        {[
          { label: "Invoice Number", key: "invoiceNumber" },
          { label: "Date Issued", key: "dateIssued" },
          { label: "Due Date", key: "dueDate" },
        ].map((field) => (
          <FormGroup
            key={field.key}
            id={field.key}
            label={field.label}
            value={getField(data, field.key)}
            onChange={(value, refId) => handleInputChange(field.key, undefined, undefined, value, refId)}
            refId={field.key}
            originalValue={getField(originalData, field.key)}
          />
        ))}
      </div>
      <div className={styles.costSection}>
        <h5>Cost</h5>
        {[
          { label: "Subtotal", key: "subtotal" },
          { label: "Total Tax", key: "totalTax" },
          { label: "Shipping Cost", key: "shippingCost" },
          { label: "Total", key: "total" },
          { label: "Currency Code", key: "currencyCode" },
        ].map((field) => (
          <FormGroup
            key={field.key}
            id={field.key}
            label={field.label}
            value={getField(data, field.key)}
            onChange={(value, refId) => handleInputChange(field.key, undefined, undefined, value, refId)}
            refId={field.key}
            originalValue={getField(originalData, field.key)}
          />
        ))}
      </div>
    </div>
  );

  const renderFormFields = (data, originalData) => {
    const fields = [
      {
        label: "From",
        key: "from",
        subfields: [
          { label: "Name", key: "name" },
          { label: "Address", key: "address" },
          { label: "Business ID", key: "businessID" },
          { label: "Tax ID", key: "taxID" },
          { label: "IBAN" },
        ],
      },
      {
        label: "To",
        key: "to",
        subfields: [
          { label: "Name", key: "name" },
          { label: "Address", key: "address" },
          { label: "Business ID", key: "businessID" },
          { label: "Tax ID", key: "taxID" },
        ],
      },
      {
        label: "Items",
        key: "items",
        subfields: [
          { label: "Description", key: "description" },
          { label: "Quantity", key: "quantity" },
          { label: "Price", key: "price" },
        ],
      },
    ];

    return (
      <div className={styles.formContainer}>
        <div className={styles.headerSection}>
          <div className={styles.fromSection}>
            <FieldComponent field={fields.find((f) => f.key === 'from')} data={data} originalData={originalData} />
          </div>
          <div className={styles.toSection}>
            <FieldComponent field={fields.find((f) => f.key === 'to')} data={data} originalData={originalData} />
          </div>
        </div>
        <InfoCostSection data={data} originalData={originalData} />
        <div className={styles.itemsSection}>
          <h5>Items</h5>
          <table className={styles.itemsTable}>
            <thead>
              <tr>
                {fields.find((f) => f.key === 'items').subfields.map((subfield) => (
                  <th key={subfield.key}>{subfield.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(getField(data, 'items')) &&
                getField(data, 'items').map((item, index) => (
                  <tr key={index}>
                    {fields.find((f) => f.key === 'items').subfields.map((subfield) => (
                      <td key={subfield.key}>
                        <input
                          type="text"
                          value={getField(item, subfield.key)?.value || ''}
                          onChange={(e) => handleInputChange('items', subfield.key, index, e.target.value, `items-${index}-${subfield.key}`)}
                          ref={(el) => (inputRefs.current[`items-${index}-${subfield.key}`] = el)}
                          className={getField(item, subfield.key)?.confidenceCheck === false ? styles.highlightLowConfidence : ''}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  if (!uploadComplete) {
    return <UploadPage onUploadStart={handleUploadStart} />;
  }

  return (
    <div className={styles.App}>
      <header className={styles.AppHeader}>
        <img src="static/assets/logo.png" alt="Logo" className={styles.AppLogo} />
        <nav className={styles.navBar}>
          <ul className={styles.navLinks}>
            <li>
              <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleHeaderUpload} multiple accept="image/png, image/jpeg, application/pdf" />
              <a href="#" onClick={() => fileInputRef.current.click()}>
                <img src="static/assets/upload.svg" alt="Upload Files" style={{ width: '48px', height: '48px' }} />
                <span>Add more files</span>
              </a>
            </li>
            <li>
              <a href="/archive" target="_blank" rel="noopener noreferrer">
                <img src="static/assets/archive.svg" alt="Archive" style={{ width: '48px', height: '48px' }} />
                <span>Archive</span>
              </a>
            </li>
          </ul>
          <div className={styles.spacer}></div>
          <ul className={styles.userInfo}>
            {userEmail && (
              <>
                <li>
                  <span>{getFirstName(userEmail)}</span>
                </li>
                <li>
                  <button onClick={() => axios.get('/logout').then(() => (window.location.href = '/login'))}>Logout</button>
                </li>
              </>
            )}
          </ul>
        </nav>
      </header>
      <div className={styles.mainApp}>
        <div className={styles.sideBar}>
          <h4>Imported images</h4>
          <ul className={styles.imageList}>
            {imageNames.map((image, index) => {
              const isProcessing = processingFiles.includes(image);
              const isSelected = currentIndex === index;

              return (
                <li
                  key={index}
                  onClick={() => {
                    if (!isProcessing) handleImageClick(index);
                  }}
                  style={{
                    cursor: isProcessing ? 'default' : 'pointer',
                    opacity: isProcessing ? 0.6 : 1,
                    fontWeight: isSelected ? 'bold' : 'unset',
                    backgroundColor: isSelected ? '#005b8f9c' : '#005b8f4a',
                  }}
                >
                  <span className={styles.imageName}>{image}</span>
                  {isProcessing && <div className={styles.spinner}></div>}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.content}>
          <div className={styles.imageContainer} ref={containerRef} onMouseMove={handleMouseMove}>
            <h4>Selected image</h4>
            <div className={styles.pageControls}>
              <button onClick={() => setCurrentPageIndex(Math.max(currentPageIndex - 1, 0))} disabled={currentPageIndex === 0 || pageImages.length <= 1}>
                Previous Page
              </button>
              <button onClick={() => setCurrentPageIndex(Math.min(currentPageIndex + 1, pageImages.length - 1))} disabled={currentPageIndex === pageImages.length - 1 || pageImages.length <= 1}>
                Next Page
              </button>
            </div>
            <div className={styles.imageControls}>
              <>
                {pageImages.length > 0 && (
                  <div className={styles.zoomableImage}>
                    <img
                      ref={imageRef}
                      src={
                        isPDF(imageNames[currentIndex])
                          ? `/uploads/${customer}/images/${pdfDir ? pdfDir + '/' : ''}${pageImages[currentPageIndex]}`
                          : `/uploads/${customer}/images/${imageNames[currentIndex]}`
                      }
                      alt="No image selected"
                    />
                  </div>
                )}
                <div className={styles.zoomControls}>
                  <img src="static/assets/zoom.svg" alt="Zoom" />
                  <button onClick={handleZoomIn}>
                    <img src="static/assets/+.svg" alt="Zoom In" />
                  </button>
                  <button onClick={handleZoomOut}>
                    <img src="static/assets/-.svg" alt="Zoom Out" />
                  </button>
                </div>
              </>
            </div>
          </div>
          <div className={styles.ocrResults}>
            <h4>Invoice data</h4>
            <div className={styles.formContainer}>
              <form id="ocr-form">{renderFormFields(ocrResult, initialOcrResult)}</form>
            </div>
            <button className="btn btn-success" onClick={() => handleImageClick((currentIndex + 1) % imageNames.length)}>
              Confirm and Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

